import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'
import styled from 'styled-components'
import Quote from 'img/quote.inline.svg'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

interface CaseLayoutProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_CaseLayout
}

const Content = styled(ParseContent)`
  @media (min-width: 992px) {
    & p {
      margin-bottom: 50px;
    }
  }
`

const Case = styled.section`
  @media (min-width: 992px) {
    padding: 0 75px;
  }
`

const QuoteContent = styled(ParseContent)`
  & p {
    font-size: 24px;
    line-height: normal;
  }
`

const QuoteDiv = styled.div`
  @media (min-width: 992px) {
    max-width: 633px;
    margin: auto;
  }

  & svg {
    min-width: 57px;
  }
`
const Title = styled.h2`
  font-weight: 700;
  font-size: 30px;
`

const Video = styled.div`
  @media (max-width: 767px) {
    padding: 56.25% 0px 0px !important;
  }

  @media (min-width: 992px) {
    margin-bottom: 75px;
  }
`

const CaseLayout: React.FC<CaseLayoutProps> = ({ fields }) => (
  <Case>
    <Title className="py-3 mb-lg-5">{fields?.title}</Title>
    <Video style={{ padding: '40.25% 0 0 0' }} className="position-relative">
      <iframe
        src={`${fields?.videoUrl}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
        frameBorder="0"
        className="position-absolute top-0 left-0 w-100 h-100"
        title="Unit 14"
      />
    </Video>
    <script src="https://player.vimeo.com/api/player.js" />
    <div className="my-4">
      <Content content={fields.description || ''} />
    </div>
    <QuoteDiv className="my-5 py-lg-5">
      <div className="d-flex">
        <Quote className="me-3" />
        <QuoteContent
          className="font-weight-bold pt-5"
          content={fields?.quote || ''}
        />
      </div>
    </QuoteDiv>
    <div className="my-4">
      <Content content={fields.secondDescription || ''} />
    </div>
    <ButtonPrimary to={fields?.button?.url || ''} className="my-4 my-lg-5">
      {fields?.button?.title}
    </ButtonPrimary>
  </Case>
)

export default CaseLayout
